import axios from 'axios';
import qs from 'qs';
import apiConfig, { reportConfigs, sapConfigs } from './config.js';

const clients = initializeClients();

function initializeClients() {
  const clients = {};

  for (const [clientName, clientConfig] of Object.entries(apiConfig.clients)) {
    clients[clientName] = initializeClient(clientName, clientConfig);
  }

  return clients;
}

function initializeClient(clientName, clientConfig) {
  const client = {};
  for (const [endpointName, endpointConfig] of Object.entries(clientConfig)) {
    client[endpointName] = initializeEndpoint(clientName, endpointConfig);
  }
  return client;
}

function initializeCustomClient(clientConfig, apiPrefix) {
  const client = {};
  for (const [endpointName, endpointConfig] of Object.entries(clientConfig)) {
    client[endpointName] = initializeEndpoint(apiPrefix, endpointConfig);
  }

  return client;
}

function initializeEndpoint(urlPrefix, endpointConfig) {
  return function () {
    let url = urlPrefix + endpointConfig.url;
    let data = {};

    if (arguments.length) {
      const method = endpointConfig.method.toLowerCase();

      if (method == 'get' || method == 'delete') {
        url += '?' + qs.stringify(arguments[0], { allowDots: true });
      } else {
        data = arguments[0];
      }
    }

    const request = {
      url,
      data,
      method: endpointConfig.method,
      headers: endpointConfig.headers,
      responseType: endpointConfig.responseType,
    };

    return createAxiosInstance().request(request);
  };
}

function createAxiosInstance() {
  return axios.create({
    baseURL: apiConfig.baseUrl,
    headers: apiConfig.getDefaultHeaders(),
  });
}

export const getApiClient = entityName => clients[entityName];

export const healthCheck = async () => {
  try {
    await createAxiosInstance().get(apiConfig.healthCheckUrl);

    return true;
  } catch {
    return false;
  }
};

export const reportsClient = initializeCustomClient(reportConfigs, 'reports');
export const sapClient = initializeCustomClient(sapConfigs, 'sap');
