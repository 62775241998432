<template lang="">
  <div :class="{ loader: true, visible: visible }" @wheel.prevent @touchmove.prevent @scroll.prevent>
    <div class="loader__content">
      <img width="30" src="../../assets/svg/ico_loader.svg" alt="" />
      <!-- Wird geladen... -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'UiLoader',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
